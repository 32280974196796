@charset "utf-8";

:root {
	--font-family-1: "Helvetica Neue";

	--cnt-width: 100%;
	--cnt-padding-lr: 2vw;
	--cnt-max-width: 1400px;

	--bt-radius: 10px;
	--grid-radius: 10px;
	--inpt-radius: 10px;

	--trans-time: 0.3s;

	--toast-sucess-bgr: #05a818;
	--toast-sucess-prog: #00de00;

	--toast-fail-bgr: #e30000;
	--toast-fail-prog: darkred;

  --primary-color: #0c53fc;
  --red: #FF233D;
}

@media screen and (max-width: 600px) {
	:root {
		--cnt-padding-lr: 10px;
	}
}

html {
	scroll-behavior: smooth;
}

body {
	font-family: var(--font-family-1);
	font-weight: 300;
}

.flex-1 {
  flex: 1
}

.items-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.fd-r {
	flex-direction: column
}

.fd-c {
	flex-direction: column
}

.just-mobile-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	max-width: 600px;
}


.bt,
.bt:visited {
	display: inline-block;
	padding: 8px 30px;
	font-size: 1.3em;
	font-weight: 500;
	cursor: pointer;
}

.bt:hover {
	transform: scale(1.05);
}

.bt.btn-logo {
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: center;
	font-size: 1em;
	font-weight: 400;
	color: white;
	text-align: center;
	align-items: center;
	gap: 10px;
	margin-top: 20px;

	background-color: var(--red);
	border-radius: 40px;
}

.bt.btn-logo img {
	width: 60px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	margin-right: 12px;
	padding-top: 10;
}

.shared-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	
	width: 100%;
	height: 100vh;
	padding: 24px;
  
	background-color: var(--primary-color);
	
	justify-content: center;
	align-items: center;

	font-family: var(--font-family-1);
	color: white
}

.shared-container .logo {
	margin-bottom: 10px;
}

.shared-container .profile-img {
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 
    0 0 10px rgba(0, 0, 0, 0.1),
    0 4px 8px rgba(0, 0, 0, 0.2);
	width: 250px;
	margin: 25px 0 35px 0;
}

.shared-container h1 {
	font-size: 2.3em;
	font-family: var(--font-family-1);
	font-weight: 500;
	color: #fff;
}

.shared-container .name {
	font-size: 1.3em;
	font-family: var(--font-family-1);
	color: #fff;
	margin-bottom: 12px;
	text-transform: capitalize;
}

.shared-container .call {
	display: flex;
	font-size: 1em;
	font-weight: 300;
	text-align: center;
	color: #fff;
	flex-wrap: wrap;
}

.shared-container .call p {
	flex-wrap: wrap;
}


.copy-right {
	display: flex;
	margin-top: 30px;
	width: 100%;
	padding: 20px 10px;
	text-align: center;
	font-family: var(--font-family-1);
	font-size: 0.7em;
	color: #EEE;
	align-items: flex-end;
	justify-content: flex-end;
}
